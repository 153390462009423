export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [];

export const dictionary = {
		"/": [6],
		"/admin": [7,[2]],
		"/admin/assessments": [8,[2]],
		"/admin/classes/[id]": [9,[2]],
		"/admin/dashboard": [10,[2]],
		"/admin/educator": [11,[2]],
		"/admin/educator/resources": [12,[2]],
		"/admin/educator/resources/[slug]": [13,[2]],
		"/admin/educator/results": [14,[2]],
		"/admin/educator/results/ResultsTable": [15,[2]],
		"/admin/educator/student-results/[id]": [16,[2]],
		"/admin/educator/student-results/[id]/export": [17],
		"/admin/login": [18,[2]],
		"/admin/profile": [19,[2]],
		"/admin/roster": [20,[2]],
		"/admin/start-assessment": [21,[2]],
		"/admin/users": [22,[2]],
		"/admin/users/[id]": [23,[2]],
		"/exam-plan/[id]": [24,[3]],
		"/rapid-visual-processing/[id]": [25,[4]],
		"/student-game": [26,[5]],
		"/users": [27]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';